import React from 'react';
import { motion } from 'framer-motion';

function Textanimation() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const wordVariants = {
    hidden: { 
      y: 100,
      opacity: 0 
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };

  const leafVariants = {
    hidden: { 
      rotate: -45,
      opacity: 0,
      scale: 0.5
    },
    visible: {
      rotate: 0,
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.div
      className="flex yeseva items-center justify-center mt-16 mb-8 px-4 overflow-hidden"
      variants={containerVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.5 }}
    >
      <div className="relative overflow-hidden">
        <motion.div 
          className="text-md md:text-3xl font-bold uppercase bg-gradient-to-r from-emerald-800 to-green-900 bg-clip-text text-transparent whitespace-nowrap font-playfair"
          variants={wordVariants}
        >
          Homes that breathe with nature 🌿
        </motion.div>
      </div>
    </motion.div>
  );
}

export default Textanimation;
