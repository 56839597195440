import React, { useState } from "react";
import { Helmet } from "react-helmet";
import bottomline from "../newComponents/asset/bottomline.png"
import { ChevronDown, ChevronUp, FileDown } from "lucide-react";

const About = () => {
  const [showMore, setShowMore] = useState(false);

  const features = [
    { icon: "https://d2ehq5aws28ia0.cloudfront.net/cottage.png", title: "Independent cottages", description: "Simplex and duplex options available" },
    { icon: "https://d2ehq5aws28ia0.cloudfront.net/customizecottage.png", title: "Customized cottages", description: "Tailored-made as per customer design" },
    { icon: "https://d2ehq5aws28ia0.cloudfront.net/residential.png", title: "Apartments", description: "1-2 BHK and Studio apartments" },
    { icon: "https://d2ehq5aws28ia0.cloudfront.net/security-agent.png", title: "Security", description: "CCTV surveillance with 24/7 security guards" },
    { icon: "https://d2ehq5aws28ia0.cloudfront.net/amenities.png", title: "Amenities", description: "Hillcrest Cafe, Clubhouse, and Trekking trails" },
    { icon: "https://d2ehq5aws28ia0.cloudfront.net/infrastructure.png", title: "Infrastructure", description: "Private project road and streetlights" }
  ];

  const uniquePoints = [
    { icon: "https://d2ehq5aws28ia0.cloudfront.net/gated.png", text: "Secured gated community" },
    { icon: "https://d2ehq5aws28ia0.cloudfront.net/sealevel.png", text: "At the altitude of 8000 feet from sea level" },
    { icon: "https://d2ehq5aws28ia0.cloudfront.net/360.png", text: "360-degree panoramic views of Nanda Devi Glaciers" },
    { icon: "https://cdn-icons-png.flaticon.com/512/484/484141.png", text: "Private road access to every property" },
    { icon: "https://cdn-icons-png.flaticon.com/512/13338/13338981.png", text: "Elite crowd and premium amenities" },
    { icon: "https://cdn-icons-png.flaticon.com/512/2140/2140147.png", text: "Snowfall Region" }
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 py-8 ">
      {/* Helmet for SEO */}
      <Helmet>
        <title>About Us- Indus Valley Mukteshwar | Property in Mukteshwar</title>
        <meta
          name="description"
          content="Indus Valley Mukteshwar, a 10 acre private gated township in the lap of the Himalayas. We build and sell cottages, apartments and villas in Mukteshwar."
        />
      </Helmet>
      <div>
        <iframe className="mx-auto mt-[120px] w-full m-5 md:w-[70%] aspect-video" src="https://www.youtube.com/embed/jTDBljQ8e_8?si=MI3sTQGlF1KSAAlL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
      {/* Hero Section */}
      <div className="text-center mb-5">
        <div className='flex flex-col mb-5 mt-[40px] justify-center items-center'>
          <h1 className='text-3xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
            Indus valley Mukteshwar
          </h1>
          <img className="ml-[-10px] mb-3 md:block hidden" src={bottomline} alt="" />
          <img className="ml-[-10px] mb-3 md:hidden block w-[300px]" src={bottomline} alt="" />
        </div>
        <p className=" text-sm md:text-xl text-justify text-stone-600 poppins mx-auto">
          Welcome to Indus Valley Mukteshwar, a place where peace resides. This is a 10-acre private gated township in the lap of the Himalayas. We are not selling cottages; we are selling the environment that we create around you.

          This project is at the height of 8000 ft from sea level, which is why it also witnesses snowfall around winter. Around the year, the weather in Mukteshwar has been cool and pleasant, and to date, we haven’t felt the need to install fans in our cottages. Places like Nainital, Shimla, Mussoorie, Dehradun are overcrowded and hustle and bustle now; these places haven’t even recorded any snowfall in the last 6-7 years due to global warming.

          Enjoy the terrific panoramic view of Nanda Devi Himalayan glaciers from every property. We have built these cottages in an organized way that every cottage faces the Himalayas. There are 70 villas in the project, out of which we have delivered 43. Around 15 families have already permanently moved to Indus Valley.
        </p>
      </div>

      {/* Key Features Grid */}
      <div className='flex flex-col mt-[40px] mb-5 justify-center items-center'>
        <p className='text-3xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
          Offerings of Indus Valley
        </p>
        <img className="ml-[-10px] mb-3 md:block hidden" src={bottomline} alt="" />
        <img className="ml-[-10px] mb-3 md:hidden block w-[300px]" src={bottomline} alt="" />
      </div>
      <div className="flex justify-center">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 mb-5">
          {features.map((feature, index) => (
            <div key={index} className=" p-6 rounded-lg transition-shadow">
              <div className="flex items-start gap-2">
                <img className="h-[30px]" src={feature.icon} alt="" />
                <h3 className="text-xl font-semibold text-stone-800 mb-2 poppins">{feature.title}</h3>
              </div>
              <p className="text-stone-600 poppins">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Unique Points Section */}
      <div className=" rounded-xl mb-5">
        <div className='flex flex-col mt-[40px] mb-5 justify-center items-center'>
          <p className='text-3xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
            Unique points of Indus Valley
          </p>
          <img className="ml-[-10px] mb-3 md:block hidden" src={bottomline} alt="" />
          <img className="ml-[-10px] mb-3 md:hidden block w-[300px]" src={bottomline} alt="" />
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {uniquePoints.map((point, index) => (
            <div key={index} className="flex items-center space-x-4 p-4  rounded-lg">
              <img className="h-[30px]" src={point.icon} alt="" />
              <span className="text-gray-800 text-xl font-semibold poppins">{point.text}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Location Section */}
      <div className=" rounded-xl mb-5">
        <div className='flex flex-col mt-[40px] mb-5 justify-center items-center'>
          <p className='text-3xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
            Location
          </p>
          <img className="ml-[-10px] mb-3 md:block hidden" src={bottomline} alt="" />
          <img className="ml-[-10px] mb-3 md:hidden block w-[300px]" src={bottomline} alt="" />
        </div>
        <p className="text-stone-600 text-center poopins">
          It is a 7-hour drive from Delhi and a 1-hour drive from Nainital, a perfect destination to invest in your dream house. The project is in Sundarkhal, which is in the main Mukteshwar region. This project is 5 minutes from the Dhanachuli market, where the residents can get all their daily essentials.
        </p>
      </div>

      {/* Main Content */}
      <div className="prose max-w-none">
        <div className='flex flex-col mt-[40px] mb-5 justify-center items-center'>
          <h2 className='text-3xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
            Why Second Homes are the Future ?
          </h2>
          <img className="ml-[-10px] mb-3 md:block hidden" src={bottomline} alt="" />
          <img className="ml-[-10px] mb-3 md:hidden block w-[300px]" src={bottomline} alt="" />
        </div>
        <p className="text-stone-600 mb-8 text-center poopins">
          Living in an urbanized colony around a concrete jungle, there must have been a time when most of the individuals residing in these cities wished to have a small place on hills where they could relax from their fast-paced lifestyle and enjoy the natural beauty of the Himalayas. A place beyond daily tensions, where they can rethink, redevelop new thoughts, and reinvent themselves.
        </p>

        <div className='flex flex-col mt-[40px] mb-5 justify-center items-center'>
          <h2 className='text-3xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>

            What is a second home or a vacation home ?
          </h2>
          <img className="ml-[-10px] mb-3 md:block hidden" src={bottomline} alt="" />
          <img className="ml-[-10px] mb-3 md:hidden block w-[300px]" src={bottomline} alt="" />
        </div>
        <p className="text-stone-600 mb-8 text-center poopins" >
          There was a time when these second homes were seen as a statement of luxury, but now they are the need of the hour for the people living in political cities. These aesthetic cottages on hills are worth every penny you are paying for as there is a high appreciation recorded on these properties, especially post-COVID, as people have started realizing the value of a peaceful environment.
        </p>

        <div className='flex flex-col mt-[40px] mb-5 justify-center items-center'>
          <p className='text-3xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>

            Investment Benefits: Indus Valley Mukteshwar
          </p>
          <img className="ml-[-10px] mb-3 md:block hidden" src={bottomline} alt="" />
          <img className="ml-[-10px] mb-3 md:hidden block w-[300px]" src={bottomline} alt="" />
        </div>
        <p className="text-stone-600 mb-8 poppins text-center poppins">
          Property Appreciation: Post covid, there was a sudden change in the market trend and customer taste. They realized the need for a place to be secluded with their loved ones.
          That’s why properties on hill stations have witnessed a great hike in their market value. If we talk about the Indus Valley in particular, there was a 40% increase in the value of the land in just the last three years.
          <br /><br />
          Personal paradise for your loved ones: Who doesn’t want to spend time with their family? A warm, small cottage in the lap of the Himalayas is the perfect way to express your feelings for your family. Create a Legacy and Family Asset for generations. Potential for Rental Income: Return on investment is one of the key things that many individuals are concerned about, and for that, after purchasing a property at Indus Valley, you can rent out your cottage with Indus Valley homestay, or you can rent it out by yourself on various hotel booking platforms like Airbnb, booking.com, MakeMyTrip.
          <br /><br />
          Relief from hot and humid weather: Places like Delhi NCR are overcrowded and have become a concrete jungle of buildings and roads. The temperatures are going beyond expectations; heat waves are one of the most crucial phases that people have to deal with, and that’s why a place in the mountains is essential for residents from plain areas.
        </p>
        <div className='flex flex-col mt-[40px] mb-5 justify-center items-center'>
          <p className='text-2xl font-bold text-center poppins text-gray-700 uppercase tracking-wider yeseva'>
            Discover Peace, Discover Indus Valley
          </p>
        </div>

        {showMore && (
          <div className="">
            {/* Additional content here */}

            <p className="text-stone-600 mb-8 poppins text-center">
              Discover the charm of Mukteshwar nestled amidst the Kumaon hills with our luxury cottages for sale. Perfectly blending contemporary comfort . These cottages offer a peaceful retreat amidst nature's beauty. Whether you seek a weekend getaway or a permanent abode in the lap of the Himalayas,
              <br /><br />
              our cottages in Mukteshwar provide you to experience life's simple joys. Our cottages in Nainital offer breathtaking views of snow-capped peaks, valleys, and dense forests right from your doorstep. Wake up to the melodious chirping of birds and the fresh mountain air, as you sip your morning tea on the veranda or cozy up by the fireplace with a good book. Our customers say these are some of the finest homes in Uttarakhand hills.
              <br /><br />
              Located amidst the neighborhood, this property boasts breathtaking views of the surrounding hills and lush greenery. The studio apartment in Uttarakhand is thoughtfully designed to maximize space and functionality, featuring a comfortable sleeping area, a well-appointed kitchenette, and a modern bathroom.
            </p>
            <h3 className="text-xl font-semibold yeseva text-gray-600">
              Key Features of our property in Uttarakhand
            </h3>
            <ul className="list-disc poppins text-gray-600 mt-[10px]">
              <li>
                <p>
                  <b>Scenic Location:</b> Enjoy the beauty of nature with hilly views of the Himalayas and verdant valleys right from your doorstep.
                </p>
              </li>
              <li>
                <p>
                  <b>Peaceful Environment:</b> Experience ambience and peacefulness in the heart of the mountains, away from the noise and pollution of urban areas.

                </p>
              </li>
              <li>
                <p>
                  <b>Well-Appointed Accommodation:</b> The property features well-designed living spaces equipped with modern amenities to ensure comfort and convenience.
                </p>
              </li>
              <li>
                <p>
                  <b>Spacious Gardens:</b> Embrace outdoor living with expansive gardens perfect for relaxation, meditation, or hosting gatherings with friends and family.
                </p>
              </li>
              <li>
                <p>
                  <b>Explore Attractions:</b> Explore nearby attractions such as Mukteshwar Temple, Chauli Ki Jali, and Bhalu Gaad Waterfalls, offering opportunities for adventure and cultural experiences.
                </p>
              </li>
              <li>
                <p>
                  <b>Investment Potential:</b> Whether as a rental property or investment opportunity, this home in Mukteshwar promises excellent returns in one of India's tourist destinations.
                </p>
              </li>
            </ul>
            <p className="text-xl mt-[40px] font-semibold yeseva text-gray-600">
              Why Mukteshwar?
            </p>
            <p className="text-stone-600 poppins mb-8 mt-5">
              Mukteshwar, with its pleasant climate, lush forests, and captivating vistas, has emerged as a favored destination for nature lovers and adventure enthusiasts alike. From trekking and birdwatching to apple orchards and colonial-era architecture, Mukteshwar offers a unique blend of experiences that appeal to travelers seeking an authentic mountain getaway.
              <br /><br />
              Mukteshwar offers a compelling choice for those seeking a serene retreat away from the hustle and bustle of popular tourist spots in Uttarakhand like Nainital, Bhimtal, and Mussoorie. Situated at an elevation of 8000 feet above sea level, Mukteshwar boasts a peaceful atmosphere and breathtaking views that are unmatched. The lower property rates here present excellent potential for investment, making it an attractive option for those seeking to capitalize on the region's growth.
              <br /><br />
              Unlike Nainital, which has experienced a decline in snowfall in recent years, Mukteshwar reliably receives snowfall between December and February every year. This consistent snowfall adds to Mukteshwar's charm as a winter destination, making it a preferred choice for those seeking snowy landscapes and a serene environment away from the tourist crowds. Moreover, its average temperature, which is consistently 5 - 10 degrees cooler than nearby hill stations like Nainital and Bhimtal, provides a refreshing escape from the summer heat.
            </p>
            <h3 className="text-xl mt-[40px] font-semibold yeseva text-gray-600">
              Why To Invest In Mukteshwar:
            </h3>
            <p className="text-stone-600 poppins mb-8 mt-5">
              Mukteshwar land cost is increasing exponentially. Post-COVID, we have seen 35% growth in land rates, which will go higher in upcoming years. Some so many families have moved permanently to the Indus Valley over the years. Investment can not always be calculated as to how much return you are getting. Sometimes, getting pure air and a peaceful environment can be counted as a mental investment for the soul.
              <br /><br />
              Investment Potential: With its growing popularity as a tourist destination and a rise in demand for vacation homes, investing in Mukteshwar real estate offers a promising appreciation value.
              <br /><br />
              Escape from the hustle and bustle: Break free from the hustle and bustle of city life and embrace a slower pace amidst nature's bounty. Mukteshwar offers a peaceful sanctuary away from the chaos.
              <br /><br />
              Year-round vacations Whether blanketed in snow during winters or adorned with blooming flowers in summers, Mukteshwar captivates visitors with its timeless beauty throughout the year. Owning a cottage here ensures year-round vacations and cherished memories.
              <br /><br />
              Large Open Personal Garden, Wide Front View
            </p>
          </div>
        )}

        <div className="flex justify-center">
          <a href="https://d2ehq5aws28ia0.cloudfront.net/pdf/indus_valley_mukteshwar.pdf"
            target="_blank"
            rel="noreferrer">
            <button
              className="flex items-center justify-center gap-3 px-6 py-3 rounded-md
                 bg-emerald-600
                 text-white 
                 shadow-sm
                 hover:bg-emerald-700
                 transform transition-all duration-200 ease-out hover:scale-105"
            >
              Download Brochure <FileDown className="w-5 h-5" />
            </button>
          </a>
        </div>

        <button
          onClick={() => setShowMore(!showMore)}
          className="mt-8 flex items-center justify-center space-x-2 mx-auto bg-green-800 text-white px-6 py-3 rounded-lg hover:bg-green-700 transition-colors"
        >
          <span>{showMore ? "Show Less" : "Read More"}</span>
          {showMore ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
        </button>
      </div>

      {/* Footer */}
      <div className="mt-5 text-center">
        <a
          href="https://www.theshubhamgroup.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-stone-800 hover:text-stone-600 font-semibold underline"
        >
          Learn more about the Shubham Group
        </a>
      </div>
    </div>
  );
};

export default About;
